<template>
  <el-main class="rh-form">
    <form-hcacttitreedit :routetolist="{ name: 'hcactivitetitres' }" />
  </el-main>
</template>
<script>
import FormHcacttitreedit from "@/components/rh/formulaires/FormHcacttitreedit";

export default {
  components: { "form-hcacttitreedit": FormHcacttitreedit },
};
</script>
