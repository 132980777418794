<template>
  <div>
    <h1>Activité hors-clinique</h1>
    <el-row style="width: 820px" v-loading="hcactiviteLoadStatus == 1">
      <el-col :span="24">
        <form-hcacttitre ref="formHcatit" />
      </el-col>
    </el-row>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormHcacttitre from "@/components/rh/ui/form/FormHcacttitre";
import { strNormalize } from "@/fonctions";

export default {
  components: { "form-hcacttitre": FormHcacttitre },

  data() {
    return {};
  },
  props: {
    routetolist: Object,
  },
  created() {
    this.$store.dispatch("hc/loadHcactivite", this.idHct);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idHct() {
      // récupère le id de l'hcactivité dans l'url
      return String(this.$route.params.idhctitre);
    },
    hcactiviteLoadStatus() {
      return this.$store.state.hc.hcactiviteLoadStatus;
    },
    hcactiviteUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.hc.hcactiviteUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.hcactiviteLoadStatus == 2) {
        // charge les données de la hcactivité
        const hct = this.$store.state.hc.hcactivite;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.hctLoaded = JSON.stringify(hct);
      } else if (this.hcactiviteLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formHcatit.$refs.FormHcacttitre.validate((valid) => {
        if (valid) {
          let hctdata = this.$store.state.hc.hcactivite;

          // enlever les espaces avant et après dans le nom de la nouvelle fac
          hctdata.hcactivite_descri = hctdata.hcactivite_descri.trim();

          // vérifie si la hcactivité est  déjà dans la bd
          const HctsInBD = this.$store.getters["hc/hcactivitesInBD"];
          // nom de l'activité en minuscule et sans espace et sans accents pour la comparaison
          const hctNormalize = strNormalize(hctdata.hcactivite_descri);
          const doublon =
            HctsInBD.filter((m) => m[0] != this.idHct && m[2] == hctNormalize)
              .length > 0;
          // prépare les données pour les comparer avec les données avant modifcation et pour l'enregistrement
          const hctdataStr = JSON.stringify(hctdata);

          // vérifie si les données ont été modifiées
          if (localStorage.hctLoaded == hctdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
            // vérifie si l'hcactivité  existe déjà dans la base de donnée
          } else if (doublon) {
            this.$alert(
              "Cette activité hors-clinique est déjà enregistrée dans la base de données",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur
            //maj l'enregistrement
            this.$store.dispatch("hc/updateHcactivite", hctdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const hctStr = JSON.stringify(this.$store.state.hc.hcactivite);
      if (localStorage.hctLoaded != hctStr) {
        // si les données ont été modifiées
        let dataObj = JSON.parse(localStorage.hctLoaded);
        this.$store.commit("hc/setHcactivite", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      // prépare les données pour les comparer avec les données avant modifcation
      const hct = this.$store.state.hc.hcactivite;
      const hctStr = JSON.stringify(hct);

      if (localStorage.hctLoaded == hctStr) {
        // si pas de modifications
        // retourne à la liste des formations post-graduées
        this.$router.push(this.routetolist);
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des hcactivités
          this.$router.push(this.routetolist);
        });
      }
    },
  },
  watch: {
    hcactiviteLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    hcactiviteUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.hcactiviteUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.hctLoaded = JSON.stringify(
          this.$store.state.hc.hcactivite
        );
      } else if (this.hcactiviteUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
